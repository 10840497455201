<template>
  <nav class="header-controls">
    <div class="header-controls__wrapper">
      <div class="header-controls__mobile-trigger">
        <button
          class="header-controls__mobile-button"
          aria-label="Menu"
          @click="openMobileNav"
        >
          <img src="@/assets/icon/mobile-nav.svg" alt="" />
        </button>
      </div>
      <div class="header-controls__search">
        <button
          class="header-controls__search-button"
          type="button"
          aria-label="Search"
          @click="openGlobalSearch"
        >
          <img src="@/assets/icon/search-icon.svg" alt="" />
          <span class="header-controls__search-button-label">Search</span>
        </button>
      </div>
      <div class="logoIcon">
        <router-link tag="a" to="/home" class="site-header__logo">
          <!--<img src="@/assets/icon/light-logo-extended_125x25.png" alt="" style="object-fit: cover; object-position: 50% 50%;">-->
          <img
            src="@/assets/icon/soccer.png"
            alt=""
            style="object-fit: cover; object-position: 50% 50%"
          />
        </router-link>
      </div>
      <div class="header-controls__account-cart">
        <a
          @click="viewAccount()"
          href="javascript:void(0);"
          class="header-controls__account"
        >
          <img src="@/assets/icon/user-icon.svg" alt="" />
        </a>
        <a
          href="javascript:void(0);"
          class="header-controls__cart"
          @click="openCart()"
        >
          <img src="@/assets/icon/bag-icon.svg" alt="" />
          <!-- <span class="visually-hidden">Cart</span> -->
          <span class="header-controls__cart-count">{{
            cartCount ? `(${cartCount})` : ''
          }}</span>
        </a>
      </div>
    </div>
  </nav>
</template>
<script>
import service from '@/utils/request'
import { isLogin } from '@/utils/util'
import { mapGetters } from 'vuex'

export default {
  name: 'HeaderBar',
  data() {
    return {
      isLogin: false,
      cartCount: 0,
    }
  },
  created() {
    this.getCartCount()
    this.isLogin = isLogin()
  },
  computed: {
    ...mapGetters(['hasNewCartTip']),
  },
  watch: {
    hasNewCartTip(val, oldVal) {
      if (oldVal === false && val) {
        this.$store.dispatch('cart/setHasNewTip', false)
        this.getCartCount()
        this.openCart()
      }
    },
  },
  methods: {
    openMobileNav() {
      this.$emit('openMobileNav')
    },
    openGlobalSearch() {
      this.$emit('openGlobalSearch')
    },
    openCart() {
      this.$emit('openCart')
    },
    getCartCount() {
      service({
        url: '/car/count',
        method: 'get',
      }).then((res) => {
        this.cartCount = res.data.count
      })
    },
    viewAccount() {
      this.$router.push('/account').catch(() => {})
      // if (isLogin()) {
      //   this.$router.push('/account').catch(() => {})
      // } else {
      //   this.$router.push('/login').catch(() => {})
      // }
    },
  },
}
</script>
<style scoped>
/* .site-header__wrapper, .main__wrapper{
  widows: 100%;
} */
@media screen and (min-width: 576px) {
  .header-controls {
    margin: 60px 0 0 0 !important;
  }
  .logoIcon {
    display: none;
  }
}
@media screen and (max-width: 576px) {
  .header-controls {
    margin: 0px !important;
    padding: 24px;
    height: 36px;
    background: #fff;
    top: 0;
  }
  .logoIcon {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
  }
  .logoIcon img {
    width: 10rem;
  }
  /* .header-controls{
    width: 100%;
    height: 60px;
    background: #fff;
    margin: 0px !important;

  } */
}
</style>
