<template>
  <div class="app-layout">
    <div v-if="barMessage" class="top-bar">
      {{ barMessage }}
    </div>
    <header-bar
      ref="headerBar"
      @openMobileNav="openMobileNav"
      @openGlobalSearch="openGlobalSearch"
      @openCart="openCart"
    ></header-bar>
    <nav-bar></nav-bar>
    <mobile-nav ref="mobileNav"></mobile-nav>
    <global-search ref="globalSearch"></global-search>
    <cart ref="cart" @change="cartChange"></cart>
    <main>
      <!--<keep-alive>-->
      <router-view />
      <!--</keep-alive>-->
    </main>
    <footer-bar></footer-bar>
    <div id="app_sidebar-wrap">
      <ul class="sidebar-classic-core-list">
        <li>
          <a
            href="mailto:customersupport@pxhuiyu.com"
            class="cursor-pointer text-underline"
            ><img
              src="@/assets/icon/youxiang.jpg"
              alt=""
              style="object-fit: cover; object-position: 50% 50%"
          /></a>
        </li>
        <li @click="whatsappShare" v-if="flag == 1">
          <img
            src="@/assets/icon/dianhua.jpg"
            alt=""
            style="object-fit: cover; object-position: 50% 50%"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import HeaderBar from './components/header-bar.vue'
import NavBar from './components/nav-bar.vue'
import MobileNav from './components/mobile-nav.vue'
import GlobalSearch from './components/global-search.vue'
import FooterBar from './components/footer-bar.vue'
import Cart from '@/components/Cart'
import service from '@/utils/request'
export default {
  name: 'Layout',
  components: {
    HeaderBar,
    NavBar,
    MobileNav,
    GlobalSearch,
    FooterBar,
    Cart,
  },
  computed: {
    flag() {
      console.log(this.$store.state)
      return this.$store.state.user.whatsapp
    },
  },
  data() {
    return {
      barMessage: '',
    }
  },
  mounted() {
    console.log(123)
    this.getMessage()
  },
  methods: {
    whatsappShare() {
      window.open('https://api.mysoccerjersey.com/open/waMe')
    },
    openMobileNav() {
      this.$refs.mobileNav.open()
    },
    openGlobalSearch() {
      this.$refs.globalSearch.open()
    },
    openCart() {
      this.$refs.cart.open()
    },
    cartChange() {
      this.$refs.headerBar.getCartCount()
    },
    getMessage() {
      service({
        url: '/open/home',
        method: 'get',
      }).then((res) => {
        this.barMessage = res.data.notice
        this.$store.dispatch('user/setWhatsApp', res.data.showWhatsapp || '')
        localStorage.setItem('shippingFree', res.data.shippingFree || null)
      })
    },
  },
}
</script>

<style lang="scss">
@media screen and (min-width: 576px) {
  .top-bar {
    z-index: 10000;
    text-align: center;
    width: 100%;
    background: #eb565f;
    padding: 12px 0px;
    color: #fff;
  }
}
@media screen and (max-width: 576px) {
  .top-bar {
    z-index: 10000;
    text-align: center;
    width: 100%;
    background: #eb565f;
    padding: 2px 0px;
    color: #fff;
  }
}
#app_sidebar-wrap {
  position: fixed;
  right: 30px;
  bottom: 80px;
  z-index: 50090;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 55px;
  .sidebar-classic-core-list {
    position: relative;
    max-height: 346px;
    margin-bottom: 18px;
    overflow: auto;
    li {
      width: 55px;
      height: 55px;
      margin-bottom: 18px;
      font-size: 28px;
      cursor: pointer;
    }
  }
}
.card-month {
  .el-date-picker__header {
    display: none !important;
  }
}
</style>
