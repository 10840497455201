import axios from 'axios'
import { Message } from 'element-ui';

const service = axios.create({
  baseURL: 'https://api.mysoccerjersey.com',
  timeout: 10000, // request timeout
})

service.interceptors.request.use(
  config => {
    const token = localStorage.getItem('satoken') || sessionStorage.getItem('satoken');
    const am = localStorage.getItem('am') || sessionStorage.getItem('am');
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    const isSupportWebp = () => {
      try {
        const img = document.createElement('canvas');
        const imgData = img.toDataURL('image/webp', 0.5)
        return imgData.indexOf('data:image/webp') === 0;
      } catch (err) {
        return false;
      }
    }
    const isWebp = isSupportWebp()
    if (token) {
      config.headers['satoken'] = token;
    }
    if (isWebp) {
      config.headers['webp'] = 'webp';
    }
    console.log()
    if (am) {
      config.headers['am'] = am;
    }
    if (tz) {
      config.headers['tz'] = tz;
    }
    // if (config.method === 'post') {
    //   config.headers['Content-Type'] = 'application/json; charset=utf-8';
    // }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  },
)

service.interceptors.response.use(
  response => {
    const { status, data } = response;
    if (status !== 200) {
      Message.error(data.msg);
      return Promise.reject(response.data)
    }
    const { code } = data;
    if (code !== 200) {
      Message.error(data.msg);
      // return Promise.reject(response.data)
    }
    return data;
  },
  error => {
    console.log('network error:' + error)
    return Promise.reject(error)
  },
)

export default service;
