import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import store from './store';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/var.css';
import './assets/css/reset.css';
import './assets/css/layout.css';
import './assets/css/media.css';
import './assets/css/common.css';
import './assets/css/base.css';
import QuickShopHover from '@/directive/quick-shop'
import CatalogMenuHover from '@/directive/catalog-menu'
import OuterClick from '@/directive/outer-click'
import DayFormat from '@/filters/dayFormat'
import MetaInfo from 'vue-meta-info'
import locale from '../node_modules/element-ui/lib/locale/lang/en'
Vue.config.productionTip = false

// Vue.use(ElementUI);
Vue.use(MetaInfo)
Vue.use(ElementUI, { locale })
Vue.directive('quick-shop-hover', QuickShopHover)
Vue.directive('catalog-menu-hover', CatalogMenuHover)
Vue.directive('outer-click', OuterClick)

Vue.filter('dayFormat', DayFormat);
Vue.prototype.shareUrl = 'https://api.mysoccerjersey.com/blog/share';
Vue.prototype.shareProductUrl = 'https://api.mysoccerjersey.com/product/share';
new Vue({
  store,
  router,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
    // document.dispatchEvent(new Event("custom-render-trigger"));
  }
}).$mount('#app')
